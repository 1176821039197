<template>
    <modal ref="modalImportarImagenes" titulo="Importar imagenes de los productos" :cargado-modal="loading" :tamano="tamanoModal" no-aceptar :adicional="textBoton" @adicional="cargar">
        <div v-show="!resultados" class="row mx-0">
            <div class="col-5">
                <div class="row mx-0 mb-3">
                    <div class="col-auto px-0">
                        <el-radio v-model="form.origen" :label="1" class="radio-green">
                            Trabajar con Barcode
                        </el-radio>
                    </div>
                    <div class="col-auto">
                        <el-radio v-model="form.origen" :label="2" class="radio-green">
                            Trabajar con SKU
                        </el-radio>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-auto px-0">
                        <el-upload
                        ref="upload"
                        action="#"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        accept=".zip"
                        >
                            <button type="button" class="btn-subir d-middle-center">
                                Subir archivo "ZIP"
                            </button>
                        </el-upload>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <p class="col-12 px-0 f-16 text-general mb-2">
                        ¿Cómo desea usar estas imagenes?
                    </p>
                    <div class="col-12 pl-0 d-flex mb-2">
                        <el-radio v-model="form.tipo_cambio" :label="1" class="radio-green">
                            Reemplazar la imagen principal de los productos
                        </el-radio>
                    </div>
                    <div class="col-12 pl-0 d-flex mb-2">
                        <el-radio v-model="form.tipo_cambio" :label="2" class="radio-green">
                            Adicionar las imagenes a las existentes de los productos
                        </el-radio>
                    </div>
                    <div class="col-12 pl-0 d-flex mb-2">
                        <el-radio v-model="form.tipo_cambio" :label="3" class="radio-green">
                            Remplazar las imagenes secundarias de los productos
                        </el-radio>
                    </div>
                </div>
            </div>
            <div class="col">
                <p class="f-16 text-general mb-3">
                    <strong>Nota: Condiciones para subir las imagenes satisfactoriamente.</strong>
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    1. Las imagenes deben llevar como nombre el Barcode o SKU de cada producto
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    2. Si desea subir mas de una imagen para el mismo producto debe usar el delimitador ";" Ej: Barcode;1
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    3. La relación de aspecto de las imagenes debe ser de 1:1 para una optima visualización
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    4. El Formato de las imagenes debe ser JPG, JPEG o PNG
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    5. El formato de importación debe ser ".ZIP"
                </p>
                <p class="f-16 text-general mb-2 f-300">
                    6. Tenga encuenta que esta accion puede tardar unos minutos, le recomendamos que sea paciente y NO actualize la pagina.
                </p>
            </div>
        </div>
        <div v-show="resultados" class="row mx-0 justify-center">
            <div class="col-10 d-middle mb-3">
                <i class="icon-ok-circled-outline text-green f-18" />
                <span class="mx-2 text-general f-16">
                    {{ respuesta.mensaje_1 }}
                </span>
            </div>
            <div class="col-10 d-middle mb-3">
                <i class="icon-cancel text-alert f-20" />
                <span class="mx-2 text-general f-16">
                    {{ respuesta.mensaje_2 }}
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import Productos from "~/services/productos/productosAdmin";

export default {
    data(){
        return {
            loading:false,
            form:{
                origen:1,
                tipo_cambio:1,
                file:null
            },
            file:null,
            resultados:false,
            respuesta:{}

        }
    },
    computed:{
        tamanoModal(){
            if(this.resultados){
                return "modal-md"
            }

            return "modal-xl"
        },
        textBoton(){
            if(this.resultados){
                return ""
            }

            return "Cargar"
        }
    },
    methods: {
        toggle(){
            this.resultados = false
            this.$refs.upload.clearFiles()
            this.$refs.modalImportarImagenes.toggle();
        },
        changeFile(file){
            this.file = file.raw
        },
        removeFile(){
            this.file = null
        },
        async cargar(){
            try {

                if(this.resultados) return
                if(this.file === null) return

                this.form.file = this.file
                const form  = this.crear_formData(this.form)
                this.loading =  true
                const {data} = await Productos.importarImagenesProductos(form)
                this.resultados = true
                this.respuesta = data.respuesta



            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading =  false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-subir{
    width: 302px;
    height: 50px;
    border-radius: 12px;
    background-color: #FFFFFF;
    font-size: 14px;
    box-shadow: 0px 2px 4px #00000014;
    border: 1px dashed #DFE4E8;
}
</style>
